import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  VStack,
  Divider,
} from "@chakra-ui/react";
import logoImage from "../assets/logo.png";

const DoctorListPage = () => {
  const [doctorList, setDoctorList] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    const fetchDoctorList = async () => {
      const doctorListApiUrl = "/api/doctor/doc-list";

      try {
        const doctorListResponse = await fetch(doctorListApiUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const doctorListData = await doctorListResponse.json();

        if (doctorListData.success) {
          setDoctorList(doctorListData.data);
        } else {
          console.error("Error fetching doctor list:", doctorListData.msg);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDoctorList();
  }, []);

  const navigate = useNavigate();

  const handleSignOut = () => {
    navigate("/");
  };

  return (
    <Box p={4}>
      <Flex align="center" justify="space-between" mb={4}>
        <Flex align="center">
          <Image src={logoImage} mr="550px" />
          <Text fontSize="4xl" fontWeight="bold">
            Doctor List
          </Text>
        </Flex>
        <Button colorScheme="red" onClick={handleSignOut}>
          Sign Out
        </Button>
      </Flex>
      <Divider borderColor="telegram" borderWidth="2px" />

      <VStack spacing={8} align="stretch" mx="500px" mt="100px">
        {doctorList.map((doctor) => (
          <Box
            key={doctor.id}
            borderWidth="1px"
            borderRadius="md"
            p={4}
            maxW="900px"
            borderColor="teal.500"
            boxShadow="md"
          >
            <Flex align="center" justify="space-between">
              <Text fontSize="xl" fontWeight="bold">
                {doctor.first_name}
              </Text>
              <Button colorScheme="teal" size="sm">
                Connect
              </Button>
            </Flex>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default DoctorListPage;
