import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Heading,
  Input,
  Text,
  Button,
  Image,
  Center,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import backgroundImage from "../assets/bg.png";
import logoImage from "../assets/logo.png";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const handleLogin = async () => {
    const loginApiUrl = "/api/default/login";

    const loginDetails = {
      username: username,
      password: password,
      role_id: "143f37f2-ca38-0ab1-2489-1e47113655fc",
      device_id:
        "55c3389cb5ddd720dc0297617f3561c43a36218a277c974c8d43d545a643f45c",
      os_id: "b93a9204-ee21-4cf9-8a94-cf5eeabf7301",
      time_zone: "Asia/Calcutta",
    };

    const headers = {
      "Content-Type": "application/json",
      APPID: "APPQUEOY6M",
      Lang: "da315627-3ece-2016-c628-b61dc5ee9be0",
    };

    try {
      const loginResponse = await fetch(loginApiUrl, {
        method: "POST",
        headers,
        body: JSON.stringify(loginDetails),
      });

      const loginData = await loginResponse.json();

      if (loginData.success) {
        localStorage.setItem("accessToken", loginData.data.access_token);
        navigate("/doctor-list");
      } else {
        console.error("Login failed:", loginData.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Flex minHeight="100vh">
      <Box
        flex="1"
        p={8}
        color="white"
        backgroundImage={`url(${backgroundImage})`}
        backgroundSize="cover"
        backgroundPosition="center"
        position="relative"
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
          zIndex="1"
        >
          <Heading size="4xl" mb={4}>
            Instapract User Centric Teleconsulting
          </Heading>
          <Box fontSize="2xl" fontWeight="bold">
            Expert Opinion Platform
          </Box>
        </Box>
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="rgba(0, 0, 0, 0.3)"
          backdropFilter="blur(10px)"
          zIndex="0"
        />
      </Box>

      <Flex
        height="100vh"
        flex="1"
        align="center"
        justify="center"
        p={8}
        bg="rgba(255, 255, 255, 0.8)"
        width="50%"
      >
        <Box width="50%">
          <Image
            src={logoImage}
            alt="Logo"
            width="300px"
            height="80px"
            mb={20}
          />
          <Text fontSize="4xl" mb={6}>
            Login
          </Text>
          <Input
            variant="filled"
            placeholder="Username"
            size="lg"
            mt={8}
            mb={6}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <InputGroup size="md">
            <Input
              variant="filled"
              placeholder="Password"
              size="lg"
              type={show ? "text" : "password"}
              mb={8}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" top="10%" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <Center>
            <Button colorScheme="blue" onClick={handleLogin}>
              Login
            </Button>
          </Center>
        </Box>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
